import {
    BrowserWalletConnector,
    WalletConnectConnector,
    // WalletConnection,
    CONCORDIUM_WALLET_CONNECT_PROJECT_ID,
    TESTNET,
    // MAINNET,

} from '@concordium/wallet-connectors';
import {detectConcordiumProvider} from '@concordium/browser-wallet-api-helpers';
import PinataService from "./../helpers/v-art-protocol-near-sdk/pinata";
import CertificatesApi from "./../helpers/v-art-protocol-near-sdk/certificates-api";
import moment from "moment";
import {IPFS_GATEWAY, CIS_CONTRACT_ADDRESS, CIS2_MULTI_CONTRACT_INFO, MARKET_CONTRACT_ADDRESS, MARKETPLACE_CONTRACT_INFO} from "../Constants";
import {AccountTransactionType, CcdAmount, sha256} from "@concordium/web-sdk";
import axios from "axios";
// import {serializeUpdateContractParameters} from "@concordium/common-sdk";

import {
    serializeUpdateContractParameters,
    deserializeReceiveReturnValue,
} from "@concordium/web-sdk";

class concordDelegate {

    constructor() {
        this.connected = false;
        this.accounts = new Map();
        this.chains = new Map();
    }

    onAccountChanged(connection, address) {
        console.log("!!!!!!!!!!!! onAccountChanged", address, this.connected)
        this.accounts.set(connection, address);
        if(this.connected) {
            window.frames[0].postMessage(
                {
                    name: "signOutRequest",
                    params: "heelllo",
                },
                "*"
            );
            this.connected = false;
        }else{
            this.connected = true;
        }
    }

    onChainChanged(connection, genesisHash) {
        console.log("!!!!!!!!!!!! onChainChanged", genesisHash, this.connected)
        this.chains.set(connection, genesisHash);
        if(this.connected) {
            window.frames[0].postMessage(
                {
                    name: "signOutRequest",
                    params: "heelllo",
                },
                "*"
            );
            this.connected = false;
        }else{
            this.connected = true;
        }
    }

    onConnected(connection, address) {
        console.log("!!!!!!!!!!!! onConnected", address, this.connected)
        this.onAccountChanged(connection, address);
    }

    onDisconnected(connection) {
        this.accounts.delete(connection);
        this.chains.delete(connection);
        console.log("!!!!!!!!!!!! onDisconnected", this.connected)
        if(this.connected) {
            window.frames[0].postMessage(
                {
                    name: "signOutRequest",
                    params: "heelllo",
                },
                "*"
            );
            this.connected = false;
        }else{
            // this.connected = true;
        }
    }
}

const delegate = new concordDelegate();

const walletConnectOpts = {
    projectId: CONCORDIUM_WALLET_CONNECT_PROJECT_ID,
    metadata: {
        name: 'Djooky NFT Marketplace',
        description: 'Djooky NFT Marketplace',
        url: '#',
        icons: ['https://walletconnect.com/walletconnect-logo.png'],
    },
};

// const baseUrl = 'https://wallet-proxy.testnet.concordium.com';
const baseUrl = process.env.VUE_APP_WALLET_URL;

let network;
// if(true === true)
network = TESTNET;
// else
//     network = MAINNET;


// export
let WalletConnector;
// export let walletConnectConnector;


const createConnector = async () => {
    if (!WalletConnector) {
        try {
            let provider = await detectConcordiumProvider()
            // The API is ready for use.
            console.log("**** createConnector", provider);
            let account = await provider.connect()
            console.log("**** createConnector detectConcordiumProvider", account);
            WalletConnector = await BrowserWalletConnector.create(delegate);
            console.log("**** createConnector BrowserWalletConnector", WalletConnector);
        } catch (e) {
            console.log(e);
            WalletConnector = await WalletConnectConnector.create(walletConnectOpts, delegate, network);
            console.log("**** createConnector WalletConnectConnector", WalletConnector);
            // const browserWalletConnection = await WalletConnector.connect();
            // console.log(browserWalletConnection);
        }
        // walletConnectConnector = await WalletConnectConnector.create(walletConnectOpts, delegate, network);
    }

    return WalletConnector ? WalletConnector.isConnected : false;
}

// export
const concordGetConnectedAccount = async () => {

    try {
        return await WalletConnector.getConnectedAccount();
    } catch (e) {
        e;
        return "";
    }

}

// export
const concordConnect = async () => {
    if (!WalletConnector)
        await createConnector();
    console.log("concordConnect", WalletConnector);
    await WalletConnector.connect();
    return WalletConnector ? WalletConnector.isConnected : false;
}



// export
const concordInit = async () => {
    // if (!WalletConnector)
    //    await createConnector();
    console.log("concordInit", WalletConnector);
}

// const browserWalletConnection = await WalletConnector.connect();
// const walletConnectConnection = await walletConnectConnector.connect();

const MICRO_CCD_IN_CCD = 1000000;
const toCcd = (ccdAmount) => {
    return new CcdAmount(ccdAmount * BigInt(MICRO_CCD_IN_CCD));
}

function i2hex(i) {
    return ('0' + i.toString(16)).slice(-2);
}

const schemaAsBuffer = (schemaBase64) => {
    const res = toBuffer(schemaBase64, 'base64');
    // Check round-trip. This requires the provided schema to be properly padded.
    if (res.toString('base64') !== schemaBase64) {
        throw new Error(`provided schema '${schemaBase64}' is not valid base64`);
    }
    return res;
}

const toParamContractAddress = (marketAddress) => {
    return {
        index: parseInt(marketAddress.index.toString()),
        subindex: parseInt(marketAddress.subindex.toString()),
    };
}

const serializeParams = (contractName, schema, methodName, params) => {
    return serializeUpdateContractParameters(contractName, methodName, params, schema);
}


class concordiumAPI {

    async concordInit() {
        return await concordInit();
    }

    async concordConnect() {
        return await concordConnect();
    }

    async concordDisconnect() {
        if (WalletConnector) {
            await WalletConnector.disconnect();
            WalletConnector = null;
        }
    }

    async concordGetConnectedAccount() {
        return await concordGetConnectedAccount();
    }

    WalletConnector() {
        return WalletConnector;
    }


    async fullMint(
        tokenId,
        media,
        preview,
        title,
        medium,
        genre,
        bio,
        description,
        artist,
        createdBy,
        year,
        price,
        artists,
        royalties,
        quantity,
        edition,
        copies,
        deposit,
        gas
    ) {

        const NFT_CONTRACT_ID = `${CIS_CONTRACT_ADDRESS.index}/${CIS_CONTRACT_ADDRESS.subindex}`;

        let previewBlob = await this.createBlob(preview)
        let mediaBlob = await this.createBlob(media)

        let previewUrl, previewHash
        let mediaUrl, mediaHash

        await PinataService.pinFile(previewBlob, 'PREVIEW_' + tokenId)
            .then(resp => {
                previewUrl = IPFS_GATEWAY + resp.ipfsHash
                previewHash = resp.hexB64
            })

        console.log("previewUrl", previewUrl);

        await PinataService.pinFile(mediaBlob, 'MEDIA_' + tokenId)
            .then(resp => {
                mediaUrl = IPFS_GATEWAY + resp.ipfsHash
                mediaHash = resp.hexB64
            })

        console.log("mediaUrl", mediaUrl);

        const date = new Date()
        const strDate = date.toISOString().slice(0, 10) + ' 00:00:00.000'

        let pinnedCertificate, certificateUrl, certificateHash, certificate;


        // try {
        //
        //     certificate = await CertificatesApi.createCertificate(
        //         tokenId,
        //         previewBlob,
        //         title,
        //         createdBy,
        //         year,
        //         edition,
        //         quantity,
        //         mediaUrl,
        //         'near',
        //         NFT_CONTRACT_ID,
        //         genre,
        //         'x',
        //         tokenId,
        //         [
        //             "adaption",
        //             "storage",
        //             "placement",
        //             "publication",
        //             "metadata",
        //             "demonstration",
        //             "personal_use",
        //             "advertising",
        //         ],
        //         moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        //     )
        //
        //     const certificateBlob = await CertificatesApi.downloadCertificate(
        //         certificate.certificate_download_url.split("/").at(-1)
        //     );
        //
        //     const fixedBlob = await this.fixPdf(certificateBlob.data);
        //
        //
        //     await PinataService.pinFile(fixedBlob, "CERT_" + title).then(
        //         (resp) => {
        //             certificateUrl =
        //                 IPFS_GATEWAY + resp.ipfsHash;
        //             certificateHash = resp.hexB64;
        //         }
        //     );
        //
        //     console.log("certificateUrl", certificateUrl);
        // } catch (e) {
        //     console.log("Impossible to create certificate", e)
        // }

        const referenceData = {
            id: tokenId,
            unique: true,
            picture: title + medium,
            name: title,
            medium: medium,
            genre: genre,
            bio: bio,
            description: description,
            artist: artist,
            createdBy: createdBy,
            ownedBy: null,
            year: year,
            price: price,
            blockchain: "Concordium",
            artistsWallets: artists,
            royaltiesWallets: royalties,
            quantiy: quantity,
            edition: edition,
            media_preview: previewUrl,
            display: {url: previewUrl},
            thumbnail: {url: previewUrl},
            media_preview_hash: previewHash,
            media: mediaUrl,
            media_hash: mediaHash,
            metadata: null,
            metadata_hash: null,
            certificate: certificateUrl,
            certificate_hash: certificateHash,
            art_size: null,
        }

        let referenceUrl, referenceHash
        // await PinataService.pinJson(referenceData, "METADATA_" + "title")
        await PinataService.pinJson(referenceData, "METADATA_" + title)
            .then(resp => {
                referenceUrl = IPFS_GATEWAY + resp.ipfsHash
                referenceHash = resp.hex864
            })

        console.log("referenceUrl", referenceUrl);

        let repackRoyalties = {}
        for (var k in royalties) {
            repackRoyalties[royalties[k].id] = royalties[k].value
        }
        let repackArtists = {}
        for (var j in artists) {
            repackArtists[artists[j].id] = artists[j].value
        }

        let nftContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        let tokenMetadata = {
            "title": title,
            "description": description,
            "media": mediaUrl,
            "media_hash": mediaHash,
            "copies": copies,
            "issued_at": strDate,
            "expires_at": strDate,
            "starts_at": strDate,
            "updated_at": strDate,
            "reference": referenceUrl,
            "reference_hash": referenceHash
        }


        // try {
        //     nftContract = this.loadNftContract()
        //     accId = nftContract.account.accountId
        // } catch (err) {
        //     txReject(err)
        // }


        let tokens = {}
        await this.mint(
            localStorage.web3_account,
            tokenId,
            referenceUrl,
            sha256([Buffer.from(JSON.stringify(referenceData))]).toString("hex"),
            quantity,
            CIS_CONTRACT_ADDRESS,
            CIS2_MULTI_CONTRACT_INFO,
            // {
            //     args: {
            //         token_id: tokenId,
            //         token_owner_id: accId,
            //         token_metadata: tokenMetadata,
            //         token_royalties: repackRoyalties,
            //     },
            //     gas: gas,
            //     amount: utils.format.parseNearAmount(deposit),
            //     accountId: accId
            // }
        )
            .then(resp => {
                txResolve(resp)
            })
            .catch(err => {
                txReject(err)
            })
        await txPromise
        return txPromise

    }


    async getSalePositions(
    ) {

        const MARKET_CONTRACT_ID = `${MARKET_CONTRACT_ADDRESS.index}/${MARKET_CONTRACT_ADDRESS.subindex}`;

        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        await this.sales(
            localStorage.web3_account,
            MARKET_CONTRACT_ADDRESS,
            MARKETPLACE_CONTRACT_INFO,
        )
            .then(resp => {

                const retValueDe = deserializeReceiveReturnValue(
                    resp,
                    MARKETPLACE_CONTRACT_INFO.schemaBuffer,
                    MARKETPLACE_CONTRACT_INFO.contractName,
                    "list",
                );

                let tokens = {};
                retValueDe[0].map(
                    (t) =>
                        (tokens[t.token_id] = {
                            contract: t.contract,
                            owner: t.owner,
                            price: BigInt(t.price),
                            primaryOwner: t.primary_owner,
                            quantity: BigInt(t.quantity),
                            royalty: t.royalty,
                            tokenId: t.token_id,
                        }))
                // return tokens;
                txResolve(tokens)
            })
            .catch(err => {
                txReject(err)
            })
        await txPromise
        return txPromise

    }


    async sales(
        account,
        tokenContractAddress,
        contractInfo
    ) {

        return this.invokeContract(
            WalletConnector,
            account,
            tokenContractAddress,
            contractInfo,
            "list"
        );
    }

    async createInitialTokenSale(
        tokenId,
        price,
        payout,
        deposit,
        gas
    ) {

        const MARKET_CONTRACT_ID = `${MARKET_CONTRACT_ADDRESS.index}/${MARKET_CONTRACT_ADDRESS.subindex}`;

        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        await this.sale(
            localStorage.web3_account,
            tokenId,
            "1",
            price,
            MARKET_CONTRACT_ADDRESS,
            MARKETPLACE_CONTRACT_INFO,
        )
            .then(resp => {
                txResolve(resp)
            })
            .catch(err => {
                txReject(err)
            })
        await txPromise
        return txPromise

    }


    async mint(
        account,
        tokenId,
        mediaUrl,
        mediaHash,
        quantity,
        tokenContractAddress,
        contractInfo,
        maxContractExecutionEnergy = BigInt(9999),
    ) {
        tokenId = Uint8Array.from(Array.from(tokenId).map(letter => letter.charCodeAt(0)));
        tokenId = tokenId.map(i2hex).join('');
        const paramJson = {
            owner: {
                Account: [account],
            },
            tokens: [[
                tokenId,
                {
                    metadata_url: {
                        url: mediaUrl,
                        hash:
                            {
                                // Some: [ sha256([Buffer.from(mediaHash)]).toString("hex") ],
                                Some: [mediaHash],
                            },
                    },
                    token_amount: quantity.toString(),
                },
            ]],
            // royalty_percentage: 5
        };

        console.log(paramJson);

        return this.updateContract(
            WalletConnector,
            contractInfo,
            paramJson,
            account,
            tokenContractAddress,
            "mint",
            maxContractExecutionEnergy,
            BigInt(0),
        );
    }


    async sale(
        account,
        tokenId,
        quantity,
        price,
        tokenContractAddress,
        contractInfo,
        maxContractExecutionEnergy = BigInt(9999),
    ) {

        const paramJson =
            {
                price: price.toString(),
                royalty: 1,
                cis_contract_address: toParamContractAddress(CIS_CONTRACT_ADDRESS),
                token_id: tokenId,
                quantity
            }

        console.log(paramJson);

        return this.updateContract(
            WalletConnector,
            contractInfo,
            paramJson,
            account,
            tokenContractAddress,
            "add",
            maxContractExecutionEnergy,
            BigInt(0),
        );
    }


    async getNftToken(tokenId) {
        let query = `${baseUrl}/v0/CIS2TokenMetadata/${CIS_CONTRACT_ADDRESS.index.toString()}/0?tokenId=${tokenId}`;

        return axios
            .get(`${query}`)
            .then(async (response) => {
                // console.log("getTokenPreview", response.data);
                console.log("getNftToken", response.data.metadata[0].metadataURL);

                try {
                    let tmp = await axios.get(response.data.metadata[0].metadataURL);
                    // console.log("getTokenPreview", tmp.data);
                    // console.log("getTokenPreview", tmp.data.display.url);
                    tmp.data.id = tokenId;
                    tmp.data.token_id = tokenId;
                    return {
                        ...tmp.data,
                        name: tokenId,
                        product: tmp.data,
                        certificate: {},
                    };
                }
                catch(e){
                    e;
                    return {};
                }
            })
            .catch(() => {
                return {};
            });
    }


    async buyLicenseSale(id, deposit, gas, owner, contract) {
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        await this.license_buy(
            localStorage.web3_account,
            id,
            deposit,
            1,
            owner,
            JSON.parse(contract),
            MARKET_CONTRACT_ADDRESS,
            MARKETPLACE_CONTRACT_INFO,
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise

    }


    async license_buy(
        account,
        tokenId,
        price,
        quantity,
        owner,
        contract,
        tokenContractAddress,
        contractInfo,
        maxContractExecutionEnergy = BigInt(9999),
    ) {
        const paramJson = {
            cis_contract_address: contract,
            token_id: tokenId,
            to: account,
            owner,
            quantity: quantity.toString()
        };

        console.log(paramJson);

        return this.updateContract(
            WalletConnector,
            contractInfo,
            paramJson,
            account,
            tokenContractAddress,
            "transfer",
            maxContractExecutionEnergy,
            BigInt(price),
        );
    }


    async updateContract(
        provider,
        contractInfo,
        paramJson,
        account,
        contractAddress,
        methodName,
        maxContractExecutionEnergy = BigInt(9999),
        amount = BigInt(0),
    ) {
        const {schemaBuffer, contractName} = contractInfo;

        console.log("signAndSendTransaction", paramJson, account,
            AccountTransactionType.Update,
            {
                maxContractExecutionEnergy,
                address: contractAddress,
                amount: toCcd(amount),
                // amount: BigInt(Math.round(Number(amount) * 1e6)).toString(),
                receiveName: `${contractName}.${methodName}`,
            },
            {
                parameters: paramJson,
                schema: {
                    type: "ModuleSchema",
                    value: schemaBuffer,
                }
            })

        return provider.signAndSendTransaction(
            account,
            AccountTransactionType.Update,
            {
                maxContractExecutionEnergy,
                address: contractAddress,
                amount: toCcd(amount),
                // amount: BigInt(Math.round(Number(amount) * 1e6)).toString(),
                receiveName: `${contractName}.${methodName}`,
            },
            {
                parameters: paramJson,
                schema: {
                    type: "ModuleSchema",
                    value: schemaBuffer,
                }
            }
        );
    }

    async invokeContract(
        provider,
        account,
        contract,
        contractInfo,
        methodName,
        params){

        const grpcClient = WalletConnector.getJsonRpcClient();
        const { schemaBuffer, contractName } = contractInfo;
        const parameter = params ? serializeParams(contractName, schemaBuffer, methodName, params) : undefined;

        const res = await grpcClient.invokeContract({
            parameter,
            contract,
            account,
            method: `${contractName}.${methodName}`,
        });

        if (!res || res.tag === "failure") {
            const msg =
                `failed invoking contract ` +
                `method:${methodName}, ` +
                `contract:(index: ${contract.index.toString()}, subindex: ${contract.subindex.toString()})`;
            return Promise.reject(new Error(msg, { cause: res }));
        }

        if (!res.returnValue) {
            const msg =
                `failed invoking contract, null return value` +
                `method:${methodName}, ` +
                `contract:(index: ${contract.index.toString()}, subindex: ${contract.subindex.toString()})`;
            return Promise.reject(new Error(msg, { cause: res }));
        }

        // console.log("invokeContract", res.returnValue);
        return Buffer.from(res.returnValue, "hex");
    }


    async createBlob(file) {
        let blobResolve, blobReject;
        let blobDone = new Promise((resolve, reject) => {
            blobResolve = resolve;
            blobReject = reject;
        });
        let reader = new FileReader();
        reader.onload = function (e) {
            let blob = new Blob([new Uint8Array(e.target.result)], {
                type: "image/jpeg",
            });
            blobResolve(blob)
        };
        reader.readAsArrayBuffer(file);
        await blobDone
        return blobDone
    }

    async fixPdf(blob) {

        console.log(blob)
        let p = new Promise((resolve, reject) => {
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                resolve(event.target.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(blob);
        });
        const arr = await p;
        const uint8array = new Uint8Array(arr);
        const pdfTriggerIndex = this.findPdfTrigger(uint8array);
        const arr2 = arr.slice(pdfTriggerIndex);
        return new Blob([arr2], {type: "application/pdf"});
    }

    findPdfTrigger(array) {
        var result = -1;
        const pdfTrigger = new Uint8Array([37, 80, 68, 70, 45, 49, 46, 55]);
        const arrEquals = (a, b) =>
            a.length === b.length && a.every((v, i) => v === b[i]);
        array.forEach((e, index) => {
            if (e == pdfTrigger[0] && result == -1) {
                const tmpArray = array.slice(index, index + pdfTrigger.length);
                if (arrEquals(pdfTrigger, tmpArray)) {
                    result = index;
                }
            }
        });
        return result;
    }

}


export default new concordiumAPI()

